import styled from 'styled-components';
import { rgba } from 'polished';
import { breakpointAndUp, colors, fonts, typography } from 'assets/styles';
import { Meditate } from 'assets/icons';
import Map from 'assets/images/map.svg';

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 22rem;

  text-align: center;
  color: ${({ $isDark }) => ($isDark ? colors.white : colors.text)};

  overflow: hidden;
  mask-image: linear-gradient(0deg, transparent 0%, ${colors.black} 20%, ${colors.black} 80%, transparent 100%);

  @media (${breakpointAndUp.tablet}) {
    height: 32rem;
    mask-image: linear-gradient(0deg, transparent 0%, ${colors.black} 35%, ${colors.black} 65%, transparent 100%);
  }

  @media (${breakpointAndUp.tabletLarge}) {
    height: 36rem;
  }

  @media (${breakpointAndUp.desktop}) {
    height: 42rem;
  }
`;

export const BackgroundMap = styled(Map)`
  position: absolute;
  top: 50%;
  left: 50%;
  right: 0;
  width: 100%;

  transform: translate(-50%, -50%);
  color: ${({ $isDark }) => ($isDark ? rgba(colors.white, 0.1) : colors.lightBrown)};
`;

export const MeditationIcon = styled(Meditate)`
  position: relative;
  font-size: 3.2rem;
`;

export const Amount = styled.h2`
  position: relative;
  font-family: ${fonts.heading};
  font-weight: 700;
  font-size: 4.6rem;
  line-height: 1.4;
`;

export const Paragraph = styled.p`
  ${typography.h5};
  position: relative;
`;
