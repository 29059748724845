import React, { ComponentProps } from 'react';
import Grid from 'components/grid';
import Section from 'components/section';
import { Content, BackgroundMap, MeditationIcon, Amount, Paragraph } from './meditation-map.style';

export type MeditationMapProps = ComponentProps<'div'> & {
  amount: number;
  paragraph?: string;
  isDark?: boolean;
  isFirst?: boolean;
};

const MeditationMap: React.FC<MeditationMapProps> = ({ amount, paragraph, isDark, isFirst }) => (
  <Section isFirst={isFirst}>
    <Grid columns="mb:1">
      <Content $isDark={isDark}>
        <BackgroundMap $isDark={isDark} />
        <MeditationIcon />
        <Amount>{amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</Amount>
        {paragraph && <Paragraph>{paragraph}</Paragraph>}
      </Content>
    </Grid>
  </Section>
);

export default MeditationMap;
